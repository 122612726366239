<template>
  <div class="history panel historypanel">
    <button class="btn-small actionbutton" @click="toggleHistory">H</button>
    <label for class="title" v-show="ShowHistory">History</label>
    <div class="historysearch" v-show="ShowHistory">
      <input type="search" class="textbox" />
      <button class="btn-small actionbutton">Lookup</button>
    </div>
    <div v-show="ShowHistory">
      <HistoryItem
        v-for="transaction in transactions"
        v-bind:key="transaction.id"
        v-bind:transaction="transaction"
      />
    </div>
    <p>{{locationName}}</p>
  </div>
</template> 

<script>
import {mapFields} from 'vuex-map-fields'
import HistoryItem from "../actions/HistoryItem.vue";

export default {
  components: {
    HistoryItem
  },

  computed: {
    ...mapFields([
            'panels',
            'locationName',
            'transactions',
            'ShowHistory'
        ]),
 
  },
  methods: {
    toggleHistory: function() {
      this.$store.dispatch.toggleHistory();
    }
  }
};
</script>

<style scoped>
.historypanel {
  margin: var(--margin-default);
}
</style>
