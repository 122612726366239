<template>
    <div :class="itemClass" @click="selectHistoryItem">
        <label for="" class="item-datetime itemheader">{{transaction.datetime | formatDate}}</label>
        <label for="" class="item-guest itemheader">{{transaction.guest}}</label>
        <label for="" class="item-item itemheader">{{transaction.item}}</label>
        <label for="" class="item-net">Net: ${{transaction.net}}</label>
        <label for="" class="item-tax">Tax: ${{transaction.tax}}</label>
        <label for="" class="item-total" >Total: ${{transaction.total}}</label>
        <div class="buttons" v-show="isSelected">
            <button class="btn-small" >Receipt</button>
            <button class="btn-small">Void</button>
            <button class="btn-small">Edit</button>
        </div>
    </div>
</template>

<script>
import format from '../../style/format.js'
export default {
    props:['transaction'],
    mixins:[format],
    computed: {
        
        isSelected:function(){
    
            return this.transaction.selected;
        },
        itemClass:function(){
            return this.isSelected ? "item selecteditem" : "item";
        }
    },

    methods:{
        selectHistoryItem:function(){
            this.$store.dispatch.selectHistoryItem(this.transaction.id);
        }
    },
    /* filters:{
        formatDate:function(value){
            if(value){
                return moment(String(value)).format('M/D/YYYY h:mm a');
            }
        }
    } */
}
</script>

<style scoped>
    .item{
        margin:var(--margin-default);
        border:var(--border-default);
        border-radius:var(--border-radius-default);
        box-shadow:var(--box-shadow-smaller);
        padding:var(--padding-default);

        display:grid;
        grid-template-columns:1fr 1fr 1fr;
        grid-template-rows:2fr 1fr 1fr 1fr;
        grid-template-areas: 
            "guest item datetime"
            "open open net"
            "buttons buttons tax"
            "buttons buttons total";
            
       /* grid-template-areas: 
            "guest item datetime datetime "
            "open net tax total";     */ 
    }
    .itemheader{
        font-weight: bold;
        margin:var(--margin-default);
    }
    .item-datetime{
        grid-area:datetime;
        text-align: right;
    }
    .item-guest{
        grid-area:guest;
    }
    .item-item{
        grid-area:item;
    }
    .item-net{
        grid-area:net;
        text-align: right;
        margin-right:5px;
    }
    .item-tax{
        grid-area:tax;
        text-align: right;
        margin-right:5px;
    }
    .item-total{
        grid-area:total;
        text-align: right;
        margin-right:5px;
    }
    .item-hr{
        grid-area:hr;
    }
    .buttons{
        grid-area:buttons;
    }
    .selecteditem{
        background-color:var(--background-color-inner);
    }
</style>