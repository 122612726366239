<template>
  <div class="loginDiv">
        <div v-if="!isGoogle">
            <form ref="loginform">
                <formentrystacked inputid="user" :label="emaillabel" v-model="username" :autocomplete="autocomplete" ref="userinput" @enter="gotopwd"/>
                <formentrystacked inputid="pass" inputtype="password" :label="passwordlabel" v-model="password" :autocomplete="autocomplete" ref="pwdinput"  @enter="login"/>
                
                <smallbutton @click="login()" toplabel="Login"/>
                
                <div v-if="pleaseWait">
                    <label for="" class="pleasewait">Please Wait...</label>
                </div> 
            </form>
            <div class="margin10">
                <linkbutton @click="forgotpassword()" toplabel="forgot password" v-if="showForgotPassword"/>
            </div>
        </div>
            
        <!-- GOOGLE -->
        <div v-if="isGoogle">
            <googlelogin @loggedin="googleloggedin" @loginfailed="googlefailed"/>
        </div>
        <div class="horizFlex " v-if="allowStoreLogin">
            <input class="entryCheckbox" type="checkbox" v-model="storeLogin" >
            <label for="" class="mediumtext">Keep Me Logged In</label>
        </div>
        <div>
            <label class="responsemessage">{{responseMessage}}</label>
        </div>
        


      <updatepassword ref="changepass" v-show="ischangepassword" :username="username" :oldpassword="oldpassword"></updatepassword>
    </div>

        
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import securityhelper from '../../services/securityhelper.js'
import datamethods from '../../services/datamethods.js'
import formentrystacked from '../controls/entry-stacked.vue'
import linkbutton from '../controls/linkbutton.vue'
import smallbutton from '../controls/button.vue'
import updatepassword from '../actions/updatepassword.vue'
import googlelogin from './googlelogin.vue'
export default {
    name:'loginuser',
    mixins:[datamethods,securityhelper],
    components:{
        formentrystacked,
        linkbutton,
        smallbutton,
        updatepassword,
        googlelogin
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'loggedIn',
            'username',
            'oldpassword',
        ]),
        useAppSecurity(){
            if(window.USE_APP_SECURITY==undefined){return false}
            else{return window.USE_APP_SECURITY}
        },
        showForgotPassword(){
            
            if(window.SHOW_FORGOT_PASSWORD==undefined){return true}
            else{return window.SHOW_FORGOT_PASSWORD}
        },
        autocomplete(){
            if(window.LOGIN_AUTOCOMPLETE==undefined){return true}
            else if(window.LOGIN_AUTOCOMPLETE){return 'on'}
            else{return 'new-password'}
        },
        useBadgeAndPin(){
            if(window.USE_BADGE_AND_PIN_LOGIN==undefined){return false}
            else{return window.USE_BADGE_AND_PIN_LOGIN}
        },
        emaillabel(){
            if(this.useBadgeAndPin){return "badge number"}
            else{return "email"}
        },
        passwordlabel(){
            if(this.useBadgeAndPin){return "pin"}
            else{return "password"}
        }
    },
    data(){
        return{
            newUserURL:window.NEW_USER_URL,
            newUserText:'Click here to create a new Account',
            password:'',

            pleaseWait:false,
            responseMessage:'',

            ischangepassword:false,

            //GOOGLE
            isGoogle:window.GOOGLE_LOGIN!="",

            token:null,
            allowStoreLogin:true,
            storeLogin:false
        }
    },
    mounted(){
       // console.log('try to focus me')
        if(this.useBadgeAndPin)    {
            this.username=null
        }
    
        this.$nextTick(()=>{
            
            this.$refs.userinput.focus() 
        })
    if(this.allowStoreLogin){
        
      
        this.token = localStorage.token
        //console.log('t' + this.token)
        if(this.token==null){return }
        if(this.token=='' || this.token=='null'){return}

        //now we call our login, with the token! (no username or pwd)
        this.post_login(this.token)
          
    }
        
  },
    methods:{
        gotopwd(){
             console.log('gotopwd')
             this.$nextTick(()=>{
                 this.$refs.pwdinput.focus()
             })
        },
        changepassword(){
            this.ischangepassword=true
            this.$refs.changepass.ChangePassword();
        },
        async forgotpassword(){
            
            //validate
            if(this.username==""){
                return this.getFailureResponse("Please enter your email address and click forgot password")
              }
            let req = {
                email:this.username,
            }
            this.pleaseWait=true;
            this.responseMessage='';
            var response = await this.callAPI(req,'forgotpass_appurl')
            //handle response
            if(response.Successful){
                 this.responseMessage = "Please check your email for your password reset link"
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;    
        },
   
        async login(){
            if(this.useAppSecurity){
                this.securitylogin()
                return;
            }


            this.responseMessage='';
            //validate
            if(this.username=="" || this.password==""){return}
            let req = {
                email:this.username,
                password:this.password,
            }
            var response = await this.callAPI(req,'walletloginuser')
            //handle response
            if(response.Successful){
                this.post_login_user(response.user)
            }else{
                this.responseMessage = response.Message
            }
           
        },
        async post_login_user(user){
            console.log('post')
            this.loggedInUser.user_personId = user.user_personId
            this.loggedInUser.name=user.name
            this.loggedIn=true
        },
        //GOOGLE HANDLING
        googleloggedin(token){
            this.post_login(token)
        },
        googlefailed(message){
            this.responseMessage =message
        },
        
        async post_login(token){
            if(this.useAppSecurity){
                this.securitypost_login(token)
                return;
            }

            //add to api
            this.AddTokenToAPI(token)

            //store?
            if(this.storeLogin){
                localStorage.token=token;
            }

            //no username/pwd
            this.responseMessage='';
            //validate
            var response = await this.callAPI(null,'badgelogin')
            //handle response
            if(response.Successful){
                console.log(response)
                this.loggedInUser= response.Person
                this.loggedIn=true
                this.$emit('loggedin')
                //this.AddUserToAPI(this.loggedInUser)
               
            }else{
                this.responseMessage = response.Message
            }
        },
        

        //app security
        
       
       
    }
}
</script>

<style >

.loginDiv{
  margin-top:60px;
  grid-area: center;
  display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size:large;
}
.margin10{
    margin:10px;
}
</style>


