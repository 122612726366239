<template>
    <div>
        <pencilbutton @click="start()" />
        <!-- MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form id="updateform" ref="updateform" @submit.prevent>
                    <label class="titlelabel">Discount {{ item.name }} (Price: {{ item.price | toCurrency }})</label>
                    
                   
                    <formentry label="Discount % Per Item"  v-model="discountPercent" @input="inputpercent"/>
                    <formentry label="Discount $ Per Item"  v-model="discountAmount"  @input="inputamount"/>
                    <formentry label="Adjusted Price Per Item" v-model="adjustedPrice"  />
                    <formentry label="Reason" isrequired="true"  v-model="reason"/>
                   
                    <smallbutton  @click="save()" toplabel="Save" />
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                </form>
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../services/datamethods'
    import format from '../../style/format'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import pencilbutton from '../controls/pencilbutton.vue'
    export default {
        name:'admindiscount',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton,
            pencilbutton
        },
        props:{
            item:{
                type:Object,
                required:true
            },
            ticketId:{
                type:Number,
                required:true
            },
            businessDayId:{
                type:Number,
                required:true
            },
            itemDiscounts:{
                type:Array,
                required:true
            }
            
        },
        mixins:[datamethods,format],
        data(){
            return{
                responseMessage:'',
    
                adjustedPrice:null,
                discountAmount:null,
                discountPercent:null,
                reason:null,
                aorp:'A',
                trueParam:true
            }
        },
        
        methods:{
           inputamount(amt){
            console.log('amt changed ' + amt)
            this.aorp='A'
            this.adjustedPrice=this.item.price - amt
            this.discountPercent= (this.discountAmount / (this.item.price))*100
            this.discountPercent = this.discountPercent.toFixed(2)
           },
           inputpercent(percent){
            console.log('per changed ' + percent)
            this.aorp='P'
            this.adjustedPrice=this.item.price - (this.item.price * (percent /100))
            this.adjustedPrice = this.adjustedPrice.toFixed(2)
            this.discountAmount = (this.discountPercent /100) * this.item.price
            this.discountAmount=this.discountAmount.toFixed(2)
           },
            async start(){
                this.message = ''
                this.$refs.mymodal.openModal()
               
                
    
            },
         
            async save(){
                if(this.$refs.updateform.checkValidity()==false){return}
    
 
                let req = {
                    ticketLineItemDiscount:{
                        id:0,
                        ticketId:this.ticketId,
                        businessDayId:this.businessDayId,
                        lineItemId:this.item.lineItemId,
                        discountAmount:this.discountAmount,
                        discountPercent:this.discountPercent,
                        reason:this.reason,
                        amountOrPercent:this.aorp,
                        quantity:this.item.quantity
                    }
                }
                var response = await this.callAPI(req,'updateticketlineitem_discount')
                if(response.Successful){
                    this.$refs.mymodal.closeModal()
                    this.$emit('refresh')
              
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            
           
        }
    }
    </script>
    
    <style>
    
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    