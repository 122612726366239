<template>
    <div>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    <label class="titlelabel">Security Override</label>
                    <br/>
                    
                    <div class="">
                        <formentry   label="Username"  v-model="overrideusername" :isrequired="trueParam" autocomplete="off"/>
                        <formentry   label="Password" inputtype="password"  v-model="overridepassword" :isrequired="trueParam" autocomplete="off"/> 
                    </div>
                
                    <smallbutton  @click="save()" toplabel="Request Override" />
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                </form>
                
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../services/datamethods.js'
    import {mapFields} from 'vuex-map-fields'
    import format from '../../style/format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from './actionmodal.vue'
    import formentry from '../controls/entry.vue'
    export default {
        name:'securityoverride',
        components:{
            smallbutton,
            actionmodal,
            formentry,
        },
        props:{
            
             buttonclass:{
              type:String,
              default:"button buttonprimary"
            },
            
        },
        mixins:[datamethods,format],
        data(){
            return{
                securityAppName:null,
                securityItemName:null,
   
                responseMessage:'',
                pleaseWait:false,
                
                trueParam:true,
                overrideusername:null,
                overridepassword:null
            }
        },
        computed:{
            ...mapFields([
    
    
            ]),
            
        },

        methods:{
            beforeAPI(){
                this.pleaseWait=true
                this.responseMessage=''
            },
            
          
            getOverride(appName, itemName){
                this.securityAppName = appName
                this.securityItemName=itemName
                this.overrideusername=null
                this.overridepassword=null
                this.responseMessage=''
                this.$refs.mymodal.openModal()
            },
            async save(){
               let req = {
                    username:this.overrideusername,
                    password:this.overridepassword,
                    appName:this.securityAppName,
                    appItemName:this.securityItemName
                }
                 this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'securityoverride')
                //handle response
                if(response.Successful){
                    this.$emit('overridesuccessful')
                    this.$refs.mymodal.closeModal()
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
   
        }
    }
    </script>
    
    <style>
    .fields{
        width:250px;
        text-align: center;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    