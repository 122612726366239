<template>
  <div class="actionfull panel-full panel" v-show="isActive">
 
    
    <br />
    <div >
      <div>
        <label class="text subtitle">Your transaction was posted successfully</label>
      </div>
      <br />
      <button class="btn" @click="startOver">OK</button>
    </div>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'

export default {
  data() {
    return {};
  },
  props: ["panelIndex"],
  computed: {
    ...mapFields([
            'panels',
            'transactionComplete'
        ]),
    isActive: function() {
      return this.panels[this.panelIndex].active;
    },
    isValid: function() {
      return this.panels[this.panelIndex].valid;
    },
    priorValid: function() {
      return this.panels[this.panelIndex - 1].valid;
    },

  },
  methods: {
    
    startOver: function() {
      this.$store.dispatch('startOver');
      this.$emit('startover')
    }
  }
};
</script>

<style scoped>
.actionfull {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>