<template>
    <div class="entry">    
        <div class="entryTextDiv">
            <label class="entryLabel" for="">{{labelText}}</label>
            <label class="required">{{requiredText}}</label>
        </div>
        <input :ref="inputRef" class="entryText capital" :type="inputtype" name="" :pattern="pattern" :placeholder="placeholder" :step="inputstep"
            :minlength="minlength" :maxlength="maxlength" :value="value" @blur="$emit('blur')" 
            @input="$emit('input',$event.target.value)" :required="isRequired" :max="max" 
            @focus="$event.target.select()" :autocomplete="autocomplete">
        
    </div>
</template>

<script>
export default {
    name:'formentry',
    props:{
        label:{
            type:String,
            required:true,
        },
        isrequired:{
            type:String,
            default:'False',
        },
        value:String,
        inputtype:{
            type:String,
            default:'text',
        },
        minlength:{
            type:String,
            default:"0",
        },
        maxlength:{
            type:String,
            default:"50",
        },
        placeholder:{
            type:String,
            default:'',
        },
        max:{
            type:String,
            default:''
        },
        inputRef:{
            type:String,
            default:'myInput'
        },
        inputstep:{
            type:String,
            default:'any'
        },
        autocomplete:{
            type:String,
            default:'on'
        }
    },
    data() {
        return {
            labelText:this.label,
            isRequired:this.isrequired=='True' || this.isrequired=='true'
        };
    },
    methods:{
        /* updateText(){
            this.$emit('input', $event.target.value)
        }, */
        leavePhone(){
           /*  if(this.inputtype=='tel'){
                this.$refs.inputRef.value=this.formatPhone(this.$refs.inputRef.value);
            } */
            
        },
        
        setfocus(){
            this.$nextTick(()=>{
            this.$refs.inputRef.focus()
            });
        },
        formatPhone(phn) {
            console.log('formatph ' + phn)
            if(phn==''){return ''}
            phn=phn.replace('-','').replace('(','').replace(')','').replace(' ','')
            console.log('cleannum ' + phn)

            if(phn.length==11 && phn.substring(0,1)=='1'){phn=phn.substring(1)}

            phn = "(" + phn.substring(0,3) + ")" + phn.substring(3,6) + "-" + phn.substring(6);
            return phn
                
        },
    },
    computed:{
        requiredText(){
            if(this.isRequired){return "*"}
            else{return ""}
        },
        pattern(){
            if(this.inputtype=="tel"){
                return "[1][0-9]{3}[0-9]{3}[0-9]{4}|[1]-[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{3}[0-9]{3}[0-9]{4}|[(][0-9]{3}[)][0-9]{3}-[0-9]{4}|[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
            }
        },
        title(){
            if(this.inputtype=='tel'){
               return "10 digit phone number"
            }
        }

    }
}
</script>

<style>
@media screen and (min-width: 812px) {
    .entry{
        flex-direction: row;
    }
}
/* phone */
@media screen and (max-width: 812px) {
   
    .entry{
        flex-direction: column;
    }
}
input:invalid {
  border-color: red;
}
select:invalid {
  border-color: red;
}
.entry{
    width: 100%;
    display:flex;

    justify-content: space-between;
}
.entryLabel{
    margin:5px;
    font-size:medium;
    text-align: left;   
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.entryCheckbox{
    margin-left:5px;
}
.entryText{
    margin:5px;
    width:95%;
    font-size:inherit;
    font:inherit;
}
.entrySelect{
    margin:5px;
    width:98%;
    font-size:inherit;
}
.entryTextDiv{
    display:flex;
    flex-direction: row;
    min-width: 250px;
}
.required{
    color:var(--required-color);
    font-size:medium;
    grid-area:req;
    margin:5px;
    text-align: right;
}
</style>