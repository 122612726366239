<template>
  <div class="hello">
    <g-signin-button :params="googleSignInParams" @success="onSignInSuccess" @error="onSignInError">Sign in with google!</g-signin-button>

    <label>{{resultmsg}}</label>
  </div>
</template>

<script>

export default {
  name: 'googlelogin',
  props: {
    msg: String
  },
  data(){
    return{
      resultmsg:'',
      googleSignInParams: {
        client_id: window.GOOGLE_LOGIN
      },
      profile:null,
      token:null,

    }
  },
  methods:{
    onSignInSuccess (googleUser) {
      this.profile = googleUser
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users
     this.profile = googleUser.getBasicProfile() // etc etc
     this.token = googleUser.getAuthResponse().id_token;
     
     this.$emit('loggedin',this.token)
    },
    onSignInError (error) {
      // `error` contains any error occurred.
      console.log('ERROR', error)
    }
  },
  

}
</script>

<style>
.g-signin-button {
  background-color:var(--primary-color);
  color:var(--light-text-color);
    border: none;
    border-radius:10px;
    width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
  padding:10px 5px 0px 5px;
  /* This is where you control how the button looks. Be creative! */

}

.g-signin-button:hover{
    background-color:var(--highlight-color);
  cursor:pointer;
}
</style>