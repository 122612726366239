<template>
  <div :class="itemClass" @click="addItem">
    <label for class="item-item">{{ payItem.name }}</label>
    <label for class="item-net">{{ payItem.price | toCurrency }}</label>
    <removebutton  @click="remove" class="removebutton" v-if="okToRemove"/>
    <img src="@/assets/check.png"  class="iconimage" v-if="!okToRemove">
  </div>
</template>

<script>
import format from '../../style/format.js'
import removebutton from '../controls/removebutton.vue'
import {mapFields} from 'vuex-map-fields'
export default {
  name:'payitem',
  props: ["payItem"],
  computed: {
    ...mapFields([
            'payItems',
            
        ]),
    
    itemClass: function() {
      return this.isSelected ? "item selecteditem" : "item";
    },
    okToRemove(){
      return this.payItem.authorizationCode ==null
    }
  },
  mixins:[format],
  components:{
    removebutton
  },
  methods: {
    addItem(){
      this.$emit('additem',this.payItem.id)
    },
    remove(){
      this.$emit('removeitem',this.payItem.id)
    }
  },
  
};
</script>

<style scoped>

.item {
  margin: var(--margin-smaller);
  border: var(--border-default);
  border-radius: var(--border-radius-default);
  padding: var(--padding-default);
  background-color: white;
  color:black;
  /* color:var(--font-color-light); */
  display: grid;
  grid-template-columns: 4fr 2fr 1fr;
  grid-template-rows: auto;
  min-width:100px;

}
.itemheader {
  font-weight: bold;
  margin: var(--margin-default);
}

.item-item {
  text-align: left;
}
.item-net {
  text-align:right;
}

.item-hr {
  grid-area: hr;
}
.buttons {
  grid-area: buttons;
}
.selecteditem {
  background-color: var(--background-color-inner);
}
.removebutton{
  margin-left:10px;
  margin-top:2px;
}
.iconimage{
  margin-left:65px;
}
</style>
