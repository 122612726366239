<template>
    <div>
        <linkbutton toplabel="Add Discount" @click="start()" />
        <!-- <pencilbutton @click="start()" /> -->
        <!-- MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form id="updateform" ref="updateform" @submit.prevent>
                    <label v-if="pleaseWait" class="pleasewait" >Please Wait</label>
                    <label class="titlelabel" v-if="couponList.length==0 && !pleaseWait">There are no discounts available</label>
                    <label class="titlelabel" v-if="couponList.length>0">Select Discount</label>
                    <div class="guestdetails panel-inner" v-for="d in couponList" :key="d.id" :value="d.id" >
                          <div class="horizFlexStretch">
                                <label class="text subtitle ticketfield" for>{{d.name}}</label>
                                <confirm  :showLink="trueParam" body="Do you want to add this discount?" title="Add Discount" @yes="checkaddCoupon(d.id)" />

                          </div>
                               
                        </div>  
                   
                    <div>
                        <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    </div>
                    
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    <securityoverride ref="override"  @overridesuccessful="overrideSuccessful"/>
                </form>
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../services/datamethods'
    import format from '../../style/format'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import pencilbutton from '../controls/pencilbutton.vue'
    import confirm from '../actions/confirm.vue'
    import securityhelper from '../../services/securityhelper.js'
    import securityoverride from '../actions/securityoverride.vue'
    export default {
        name:'admindiscountwhole',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton,
            pencilbutton,
            confirm,
            securityoverride
        },
        props:{
            
            ticketId:{
                type:Number,
                required:true
            },
            
            
        },
        mixins:[datamethods,format,securityhelper],
        data(){
            return{
                responseMessage:'',
                pleaseWait:false,
                couponList:[],
                discountId:null,
                trueParam:true
            }
        },
        
        methods:{
           
            async start(){
                this.responseMessage = ''
                this.getCoupons();
                this.$refs.mymodal.openModal()
               
                
    
            },
            checkaddCoupon(id){
                this.discountId=id //save for later
                if(this.canAccess_allowOverride('POS','Add Discount')){
                    //console.log('already has access')
                    this.addCoupon(id)
                }
            },
            overrideSuccessful(){
                //console.log('override!!! call success function')
                this.addCoupon(this.discountId)
            },
            async addCoupon(id)  {        
 
                let req = {
                    ticketId:this.ticketId,
                    discountId:id,
                }
                var response = await this.callAPI(req,'addcoupon')
                if(response.Successful){
                    this.$refs.mymodal.closeModal()
                    this.$emit('refresh')
              
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            async getCoupons(){
          
                this.pleaseWait=true
                let req = {
                    ticketId:this.ticketId,
         
                }
                var response = await this.callAPI(req,'coupons')
                if(response.Successful){
                    this.couponList=response.DiscountList
                
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
           
        }
    }
    </script>
    
    <style>
    
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    