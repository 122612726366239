<template>
    <div class="pmtdiv">
        <smallbutton :toplabel="name" @click="startAdd()" v-if="showButton" :buttonclass="buttonClass" :disabled="disabled"/>
        <linkbutton :toplabel="name" @click="startAdd()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"  > 
            <template v-slot:body>
                
                    <label class="titlelabel">{{name}}</label>
                    <br/>
                    <div class="">
                        <input ref="lookupguest" type="search" placeholder="Lookup Guest" class="textbox" v-model="lookupString" @keyup.enter="lookupGuest()" />
                        <button class="btn-small actionbutton" @click="lookupGuest" >Lookup</button>
                    </div>
                    


                    <div class="">
                        <label class="ballabel">{{ guest.name }}</label>
                        <label class="ballabel">{{accountBalance}}</label>
                    </div>
                    <br>
                    <div>
                        <smallbutton  @click="close()" toplabel="Close"/>
                    </div>
                    
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                
                
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../style/format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import datamethods from '../../services/datamethods.js'
    export default {
        name:'accountbalance',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            name:{
                type:String,
                default:'Account Balance'
            },
            defaultAmount:{
                type:Number,
            },
            numberDataLabel:{
                type:String,
                default:''
            },
            buttonClass:{
                type:String,
                default:'button buttonpay'
            },
        disabled:{
            type:Boolean,
            default:false
        }
            
        },
        mixins:[format,datamethods],
        data(){
            return{
                
                lookupString:null,

                Accounts:[],
                guest:{}
                
            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait',
                'location'
            ]),
            accountBalance(){
                if(this.Accounts==undefined){ return null; }
                if(this.Accounts.length==0){return null}
                return 'Balance: $' + this.Accounts[0].currentBalance.toFixed(2)

            },
        },
        methods:{
            close(){
                this.responseMessage=null
                this.$refs.mymodal.closeModal()
            },
            startAdd(){
                this.lookupString=null
                this.guest={}
                this.Accounts=[]
                this.responseMessage=null
                this.balance=0
                this.$refs.mymodal.openModal()
                this.$nextTick(() => {
                    this.$refs.lookupguest.focus();
                    })
            },
           
            async lookupGuest(){
                this.guest={}
                this.Accounts=[]
                let req = {
                     lookupString:this.lookupString
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'lookupguest')
                //handle response
            
                if(response.Successful){
                
                    this.guestList= response.GuestList
                    if(this.guestList.length==0){this.responseMessage="No Guests Found"}
                    
                    //autoselect?
                
                    else if(this.guestList.length==1){
                            this.guest=this.guestList[0]
                            this.GetGuestAccount(this.guest)
                    }else{
                            this.responseMessage="Multiple Guests"
                    }
                }
                this.pleaseWait=false;
            },
            async GetGuestAccount(){
                let req = {
                personId:this.guest.id,
                locationId:this.location.id
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'AccountByGuest')
                //handle response
            
                if(response.Successful){
                    this.Accounts = response.AccountList
                     
               
                }else{
                    this.responseMessage = response.Message
                    this.pleaseWait=false;
                }
                    
            },
        }
    }
    </script>
    
    <style>
    .balbutton{
        height:25px;
        background-color:var(--button-color);
        color:var(--light-text-color);
        border:none;
        border-radius:2px;
        padding:5px;
        margin-top:5px;
    }
    .ballabel{
        margin-left:32px;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    