<template>
  <div :class="itemClass" @click="addCartItem" :style="itemStyle">
    <label for class="item-item">{{ cartItem.name }}</label>
    <div class="personPhoto" v-if="cartItem.imageFilename!=null">
            <img :src="getProductImage"  width="30" class="productimage" >
    </div>
    <div class="horizFlex">
      <label for class="item-net">{{ cartItem.price | formatMoney }}</label>
      <label v-if="cartItem.isFinancialAidEligible"> *FA*</label>
    </div>
    
    
    
  </div>
</template>

<script>
// import { getEnvironmentData } from 'worker_threads';

import {mapFields} from 'vuex-map-fields'
export default {
    name:'availableitem',
  props: ["cartItem"],
  computed: {
    ...mapFields([
            'imageBasePath'
        ]),
    isSelected: function() {
      
      return this.cartItem.selected;
    },
    itemClass: function() {
      var c = "item"
      //c=c+ " color" + this.cartItem.typeIndex
      if(this.cartItem.typeIndex>=0){
        c=c+ " secondarycolor"
      }
      if(this.isSelected){c=c+ " selecteditem"}
      return c
    },
    itemStyle:function(){
      if(this.cartItem.displayColor!=null){
       // console.log("color:"  + this.cartItem.displayColor)
        var sty = {backgroundColor: this.cartItem.displayColor  }
       // console.log(sty)
        return sty
      }
      return {}
    },
    getProductImage(){
       
       if(this.cartItem.imageFilename==null){return null}
       if(this.cartItem.imageFilename=='newproduct/jpg'){return null}
        return this.imageBasePath + this.cartItem.imageFilename;
    },
  },

  methods: {
    addCartItem(){
      console.log('add')
      this.$emit('additem',this.cartItem.id)
    }
  },
  filters: {
    formatMoney: function(value) {
      if (value) {
        return "$" + Number(value).toFixed(2);
      }
    }
  }
};
</script>

<style scoped>
.item {
  margin: var(--margin-smaller);
  border: var(--border-default);
  border-radius: var(--border-radius-default);
  padding: var(--padding-default);
  background-color: var(--button-color);
  color:var(--light-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width:100px;

}
.secondarycolor{
  background-color:var(--button-color);
}
.itemheader {
  font-weight: bold;
  margin: var(--margin-default);
}

.item-item {
 font-size:large;
}
.item-net {
  
}


.selecteditem {
  /* background-color: var(--background-color-inner); */
  border-color:var(--highlight-button-color);
  border-width: 3px;
}
.color1{
    background-color:rgb(132, 140, 185);
}
.color2{
    background-color:rgb(133, 196, 133);
}
.color3{
    background-color:rgb(211, 134, 134);
}
.color4{
    background-color:rgb(207, 107, 207);
}
.color5{
    background-color:rgb(230, 95, 117);
}
.color6{
    background-color:rgb(255, 145, 0);
}

</style>
