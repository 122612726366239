<template>
  <div :class="itemClass" @click="addCartItem">
    <quantity :currentquantity="cartItem.quantity" @select="updatequantity"/>
    <!-- <label for class="item-item">{{ cartItem.quantity }}</label> -->
    <div class="horizFlex">
      <label for class="item-item">{{ cartItem.name }}</label>
      <label v-if="cartItem.isFinancialAidEligible"> *FA*</label>
    </div>
    
    <label for class="item-net">{{ cartItem.price | toCurrency }}</label>
    
    <removebutton  @click="remove" class="removebutton"/>
    <!-- <linkbutton toplabel="Refund" @click="refund" v-if="showRefund"/> -->
    <admindiscount :item="cartItem" :itemDiscounts="ticket.lineItemDiscounts" :ticketId="ticket.id" :businessDayId="ticket.businessDayId" @refresh="refresh" v-if="ShowAdminLineItemDiscount"/>
    <!-- <div class="buttons" v-show="isSelected"></div> -->
  </div>
</template>

<script>
import format from '../../style/format.js'
import removebutton from '../controls/removebutton.vue'
import {mapFields} from 'vuex-map-fields'
import admindiscount from '../actions/adminDiscount.vue'
import quantity from '../actions/quantity.vue'
import linkbutton from '../controls/linkbutton.vue'
export default {
  
  props: ["cartItem"],
  computed: {
    ...mapFields([
            'cartItems',
            'ticket'
            
        ]),
    isSelected: function() {
  
      return this.cartItem.selected;
    },
    itemClass: function() {
      return this.cartItem.isAuto ? "item selecteditem" : "item";
    },
    ShowAdminLineItemDiscount(){
      if(window.SHOW_ADMIN_LINEITEM_DISCOUNT==undefined){return false}
      else{return window.SHOW_ADMIN_LINEITEM_DISCOUNT}
    },
    showRefund(){
      if(window.SHOW_REFUND==undefined){return false}
      else{return window.SHOW_REFUND}
    }
  },
  mixins:[format],
  components:{
    removebutton,
    admindiscount,
    quantity,
    linkbutton
  },
  methods: {
    addCartItem(){
      console.log('add')
      this.$emit('additem',this.cartItem.id)
    },
    remove(){
      this.$emit('removeitem',this.cartItem.id)
    },
    refund(){
      console.log('inrefund')
      this.$emit('refunditem',this.cartItem.lineItemId)
    },
    refresh(){
      this.$emit('refresh')
    },
    updatequantity(qty){

      this.$emit('updatequantity',this.cartItem,qty)
      this.$forceUpdate()
    }
  },
  
};
</script>

<style scoped>
.item {
  margin: var(--margin-smaller);
  border: var(--border-default);
  border-radius: var(--border-radius-default);
  padding: var(--padding-default);
  background-color: var(--);
  color:var(--font-color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width:100px;

}
.item {
  margin: var(--margin-smaller);
  border: var(--border-default);
  border-radius: var(--border-radius-default);
  padding: var(--padding-default);
  background-color: white;
  color:black;
  /* color:var(--font-color-light); */
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 1fr auto;
  grid-template-rows: auto;
  min-width:100px;

}
.itemheader {
  font-weight: bold;
  margin: var(--margin-default);
}

.item-item {
  text-align: left;
}
.item-net {
  text-align:right;
}

.item-hr {
  grid-area: hr;
}
.buttons {
  grid-area: buttons;
}
.selecteditem {
  border-color: blue;
  border-width: 1;
}
.removebutton{
  margin-left:10px;
  margin-top:2px;
}
</style>
