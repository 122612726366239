<template>
<div>
    <linkbutton toplabel="Add Guest" @click="startUpdateguest()" v-if="isNew"/>
    <pencilbutton @click="startUpdateguest()" v-if="!isNew" />
    <!-- MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form id="updateguestform" ref="updateguestform" @submit.prevent>
                <label class="titlelabel">Update Guest</label>
                <formentry :label="guestfirstNameLabel" isrequired='True' v-model="guest.FirstName" />
                <formentry :label="guestlastNameLabel" isrequired='True' v-model="guest.LastName"  />
               
                <!-- <formentry label="Phone" inputtype="tel" isrequired="True" @blur="leavePhone" v-model="guest.PhoneNumber"/> -->
                <formentry label="Email" inputtype="email" isrequired="True" v-model="guest.EmailAddress"/>
                <formentry label="Student #" isrequired="True" v-model="guest.Code"/>
               <!--  <formentry label="Address 1"  v-model="guest.AddressLine1"/>
                <formentry label="Address 2"  v-model="guest.AddressLine2"/>
                <formentry label="City"  v-model="guest.City"/>
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">State</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="guest.StateID" >
                        <option v-for="state in states" :key="state.id" :value="state.id" >
                            {{state.name}}
                        </option>
                    </select>
                </div>
                <formentry label="Zip"  v-model="guest.ZipCode"/> -->

                <smallbutton  @click="saveUpdatedguest()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../services/datamethods'
import format from '../../style/format'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updateguest',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        guestId:{
            type:Number,
            required:true
        },
        isNew:{
            type:Boolean,
            default:false
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            responseMessage:'',

            guest:{},


            guestfirstNameLabel:'Guest First Name',
            guestlastNameLabel:'Guest Last Name',
            guestPreferredLabel:'Preferred Name (Nickname)',

            states:[]
        }
    },
    
    methods:{
        async getstates(){
            this.message = ''


            var response = await this.callAPI(null,'states')    
            if(response.Successful){
                this.states=response.stateList
            }else{
                this.message = response.Message
            }

        },
        async startUpdateguest(){
            this.getstates()
            this.message = ''

            if(this.guestId==-1){
                this.newGuest();
                this.$refs.mymodal.openModal()
                return
            }

            let req = {
                Guest:{
                    ID:this.guestId
                }
            }

            var response = await this.callAPI(req,'guest')    
            if(response.Successful){
               
                this.guest=response.Guest;
                this.guest.PhoneNumber=this.$options.filters.formatPhone(this.guest.PhoneNumber);

                this.$refs.mymodal.openModal()
            }else{
                this.message = response.Message
            }

        },
     
        async saveUpdatedguest(){
            if(this.$refs.updateguestform.checkValidity()==false){return}

            this.guest.PhoneNumber=this.$options.filters.cleanPhone(this.guest.PhoneNumber)

            let req = {
                Guest:this.guest,
            }
            var response = await this.callAPI(req,'saveguest')
            if(response.Successful){
                this.$refs.mymodal.closeModal()

                var lookupstring = this.isNew ? this.guest.PhoneNumber : ''
                this.$emit('refresh',lookupstring)
          
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        newGuest(){
            this.guest={
                id:-1,
                name:'',
                FirstName:'',
                LastName:'',
                AddressLine1:'',
                AddressLine2:'',
                City:'',
                StateID:-1,
                ZipCode:'',
                IsActive:true
            }
        },
        leavePhone(){

            this.guest.PhoneNumber=this.$options.filters.formatPhone(this.guest.PhoneNumber);
            
            
        },
       
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
</style>





























