export default {
    


	NoItemsItem() {
		let noneItem = {
			id: 0,
			Name: 'None Available',
			net: '0.00',
			tax: '0.00',
			total: '0.00',
			selected: false,
			added: false
		};
		return noneItem;
    },
    newGuest(){
        let guest ={
            name: '',
            address: '',
            phone: '',
            cityStateZip: ''
        } 
        return guest;
    },
    initialPanels(){
        let panels=[
            {
                available: true,
                active: true,
                valid: false,
                title: 'Guest',
                layoutClass: 'guest',
                subtitle1:null,
                subtitle2:null,
                subtitle3:null,
                subtitle4:null,
                image:null
            },
            {
                available: false,
                active: false,
                valid: false,
                title: 'Cart',
                layoutClass: 'lineitem',
                image:null
            },
            {
                available: false,
                active: false,
                valid: false,
                title: 'Payment',
                layoutClass: 'payment',
                image:null
            },
            {
                available: false,
                active: false,
                valid: false,
                title: 'Complete',
                layoutClass: 'action',
                image:null
            }
        ];
        return panels;
    }
};
