<template>
    <div class="pmtdiv">
        <!-- <smallbutton :toplabel="name" @click="startAdd()" v-if="showButton" :buttonclass="buttonClass"/>
        <linkbutton :toplabel="name" @click="startAdd()" v-if="!showButton"/> -->
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform" v-on:keyup.enter="Save">
                    <label class="titlelabel">{{name}}</label>
                    <br/>

                    <div class="entry">    
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Price $</label>
                            <label class="required">*</label>
                        </div>
                        <input  class="entryText"  name="" ref="pricefield" required="true" v-model="price" v-on:keyup.enter="Save"
                            @focus="$event.target.select()" >
                        
                    </div>
                    <!-- <formentry  isrequired="True" label="Price $"  v-model="price"  /> -->
                    
    
                    <smallbutton  @click="Save()" toplabel="Save" />
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                </form>
                
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../style/format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    export default {
        name:'setprice',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            name:{
                type:String,
                default:'Set Product Price'
            },
            minimumAmount:{
                type:Number,
            },
            numberDataLabel:{
                type:String,
                default:''
            },
            buttonClass:{
                type:String,
                default:'button buttonpay'
            }
            
        },
        mixins:[format],
        data(){
            return{
                
                price:0,
                
            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait'
            ]),
            
        },
        methods:{
            
            start(){
                this.responseMessage=null
                this.price=this.minimumAmount
                this.$refs.mymodal.openModal()
                this.$nextTick(()=>{
                    this.$refs.pricefield.focus()
                });
            },
            Save(){
                if(this.price < this.minimumAmount){
                    this.responseMessage="Please enter a price greater than or equal to " + this.minimumAmount
                    return;
                }
                this.$emit('save',this.price)
                this.$refs.mymodal.closeModal()
            }
        }
    }
    </script>
    
    <style>
    
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    