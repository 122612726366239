<template>
    <div>
        <linkbutton :toplabel="currentquantity" @click="Update()"/>
        <!-- ACCT NOTE MODAL -->
          <actionmodalnarrow ref="mymodal"> 
            <template v-slot:body>
               
                
                <div class="centered" >
                    <label class="titlelabel">Update Quantity</label>
                    <div class="centered">
                        <input ref="numberinput" class="gradeinput2" v-model="quantity" >
                    </div>  
                    <div class="keypad">
                        <button class="selectgradebutton" @click="enter('7')">7</button>
                        <button class="selectgradebutton" @click="enter('8')">8</button>
                        <button class="selectgradebutton" @click="enter('9')">9</button>
                        <button class="selectgradebutton" @click="enter('4')">4</button>
                        <button class="selectgradebutton" @click="enter('5')">5</button>
                        <button class="selectgradebutton" @click="enter('6')">6</button>
                        <button class="selectgradebutton" @click="enter('1')">1</button>
                        <button class="selectgradebutton" @click="enter('2')">2</button>
                        <button class="selectgradebutton" @click="enter('3')">3</button>
                        
                        <button class="selectgradebutton" @click="enter('0')">0</button>
                        <button class="selectgradebutton" @click="clear()">Clear</button>
                    </div>
                    <div class="horizFlex">
                        <smallbutton  @click="entered()" toplabel="Save" />
                        <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                    </div>                
                </div>
                

             
            </template>
          </actionmodalnarrow>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import datamethods from '../../services/datamethods.js'
    import format from '../../style/format.js'
    import linkbutton from '../controls/linkbutton.vue'
    import smallbutton from '../controls/button.vue'
    import actionmodalnarrow from '../controls/actionmodalnarrow.vue'
   
    export default {
        name:'quantity',
        components:{
            smallbutton,
            linkbutton,
            actionmodalnarrow,

        },
        props:{
            currentquantity:{
                type:String,
            },
           
    
        },
        mixins:[datamethods,format],
        data(){
            return{
                quantity:null,
            }
        },
        computed:{
            ...mapFields([
               
            ]),
            
            
        },
        methods:{
           clear(){
            this.quantity=null
           },
           Update(){
            this.quantity=null
    
            this.$refs.mymodal.openModal()
           },
           enter(digit){
            if(this.isTextSelected(this.$refs.numberinput)){
                this.quantity=digit
            }else{
                this.quantity= this.quantity + digit
            }
                
           },
           isTextSelected(input) {
                if (typeof input.selectionStart == "number") {
                    return input.selectionStart == 0 && input.selectionEnd == input.value.length;
                } else if (typeof document.selection != "undefined") {
                    input.focus();
                    return document.selection.createRange().text == input.value;
                }
           },

            cancelupdate(){
                this.$refs.mymodal.closeModal()
            
            },
            
            entered(){
                
                this.$emit('select', this.quantity)
                
                this.$refs.mymodal.closeModal()

            }
        }
    }
    </script>
    
    <style>
   
    .selectgradebutton{
    height:70px;
    width:70px;
    background-color: var(--frame-color);
    border:1px solid gray;
    font-size:larger;
    margin:5px;
  }
 
  .gradeinput2{
    text-align: center;
    width:100px;

  }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    ./quantity.vue/index.js